/* Фиксированный хедер */
var $header = $('.header');
var lastScrollTop = 0;
var offset_value = $header.hasClass('header__compact') ? 100 : 400;
$(window).on('scroll', function(e){
	var st = window.pageYOffset || document.documentElement.scrollTop;
	if ($(this).scrollTop() >= offset_value && st < lastScrollTop) {
	   $('body').addClass('fixed__nav');
	} else {
	   $('body').removeClass('fixed__nav');
	}

	lastScrollTop = st;
});


/* Моб поиск */
var $search = $('#search');
$('.open-search-panel').on('click', function(e) {
	e.preventDefault();
	$('.modal').modal('hide');
	$search.addClass('active');
});

$('.header__mobile .close').on('click', function(e) {
	e.preventDefault();
	$search.removeClass('active');
});

/* Tooltips */
$('[data-toggle="tooltip"]').tooltip({ html : true });




/* Слайдер скриншотов */
$game__slider = $('.game__slider');
if($game__slider.length) {
	$game__slider.slick({
		variableWidth : true,
		arrows: true,
		prevArrow: "<button class='slick-arrow__prev'><i class='icon-arrow_left'></i></button>",
		nextArrow: "<button class='slick-arrow__next'><i class='icon-arrow_right'></i></button>",
		responsive: [
			{
				breakpoint : 767,
				settings : {
					arrows : false
				}
			}
		]
	});

  baguetteBox.run('.game__slider', {
    animation: 'fadeIn',
    noScrollbars: true
  });
}

/* Получить все комментарии игры */
var $getAllReviews = $('.getAllReviews');
if($getAllReviews.length) {
	$('.getAllReviews').on('click', function(e) {
		e.preventDefault();
		var path = $(this).data('action');
		var $container = $($(this).data('container'));
		$.ajax({
			url: path,
			success: function(response){
				/* TEST */
				response = $container.html();
				/* END TEST */
				$container.append(response);
				$(this).hide();
			}.bind(this)
		});
	});
}


/* Рейтинг игры */
var $rate = $('.rate_circle');
if($rate.length) {

	var rate_value = parseFloat($rate.data('value'));
	var bar_value = rate_value/5;


	var bar = barInit(
		$('.game__rating .rate_circle').get(0),
		function(state, circle) {
			circle.path.setAttribute('stroke', state.color);
			$(circle.text).css('color', state.color);
		}
	);

	var $rate_value = $('.rate_value');
	var modal_bar = barInit(
		$('.modal-comments .rate_circle').get(0),
		function (state, circle) {
			circle.path.setAttribute('stroke', state.color);

			if(state.a != 0) {
				$rate_value.css('color', '#000');
				$(circle.text).css('color', state.color);
			} else {
				$(circle.text).css('color', '#C6C6C6');
				$rate_value.css('color', '#C6C6C6');
			}
		}
	);

	$('.game__rating').scrollfire({
		offset: 200,
		bottomOffset: 200,
		onBottomIn: function( elm, distance_scrolled ) {
			bar.animate(bar_value);

			$('.stars__item_border_active').each(function(e) {
				var width = $(this).data('width');
				$(this).css('width', width+'%');
			});
		}
	});

	var $vote_elements = $('.vote');
	var $vote_items = $('.vote__item');
  var $voteInput = $('.vote__value input.rating-input');

  $('.modal-comments .vote__item').slice(0, $voteInput.val()).addClass('selected');
  $('.vote-on-hover .vote__item').slice(0, $voteInput.val()).addClass('selected');
  $('.dropdown-votes .vote__item').slice(0, $voteInput.val()).addClass('selected');
  $('.vote_mobile .vote__item').slice(0, $voteInput.val()).addClass('selected');

  $vote_items
    .on('mouseenter', function (e) {
      var $current = $(e.currentTarget);
      var indx = $current.index();

      $current.parents('.modal-comments').length > 0 && modal_bar.animate((indx+1)/5);

      $rate_value.html(indx+1);

      $vote_items.removeClass('active selected');

      $vote_elements.each(function (i, el) {
        $(this).find('.vote__item').eq(indx).addClass('active').prevAll().addClass('active');
      });
    })
    .on('mouseleave', function (e) {
      $('.modal-comments .vote__item').slice(0, $voteInput.val()).addClass('selected');
      $('.vote-on-hover .vote__item').slice(0, $voteInput.val()).addClass('selected');
      $('.dropdown-votes .vote__item').slice(0, $voteInput.val()).addClass('selected');
      $('.vote_mobile .vote__item').slice(0, $voteInput.val()).addClass('selected');

      var $current = $(e.currentTarget);
      var selected = $vote_elements.eq(0).find('.vote__item.selected').length;

      $vote_items.removeClass('active');

      if ($current.parents('.modal-comments').length > 0) {
        if (selected == 0) {
          modal_bar.animate(0);
          $rate_value.html(0);
        } else {
          modal_bar.animate(selected / 5);
          $rate_value.html(selected);
        }
      }
    });

  $(document).on('click', function (e) {
    var $voteItem = $('.vote__item');
    if ($voteItem.is(e.target) || $voteItem.has(e.target).length > 0) {
      var $current = $voteItem.is(e.target) ? $(e.target) : $(e.target).closest($voteItem);
      var indx = $current.index();
      var $voteInput = $current.closest('.vote__stars').siblings('.vote__value').find('input.rating-input');

      $voteItem.removeClass('selected');
      $('.vote').each(function (i, el) {
        $(this).find('.vote__item').eq(indx).addClass('selected').prevAll().addClass('selected');
      });
      $voteInput.val(indx + 1);
      $current.parents('.modal-comments').length === 0 && $voteInput.trigger('change', [$voteInput.closest('form').attr('id')]);
    }
  });

	function barInit(el, stepFunc) {
		return new ProgressBar.Circle(el, {
			color: '#EBEBEB',
			trailColor: '#E9E9E9',
			trailWidth: 2,
			duration: 1400,
			easing: 'bounce',
			strokeWidth: 6,
			text: {
				value : '<i class="icon-star"></i>',
				style : {
					color: '#EBEBEB',
		            position: 'absolute',
		            left: '50%',
		            top: '50%',
		            padding: 0,
		            margin: 0,
		            // You can specify styles which will be browser prefixed
		            transform: {
		                prefix: true,
		                value: 'translate(-50%, -50%)'
		            }
				}
			},
			from: { color: '#cc3b3b', a: 0 },
			to: { color: '#f4ba11', a : 1 },
			// Set default step function for all animate calls
			step: stepFunc
		});
	}

	var $length_rewiev = $('.modal-comments__length_rewiev span');
	$('.js-counter').on('keyup', function (e) {
		var length = $(this).val().length;
		var max = $(this).attr('maxlength');
		$length_rewiev.text(max - length);
	});
}



/* Слайдер превью игр */
var $promo_slider = $('.promo_slider');
if($promo_slider.length) {
  $promo_slider.slick({
		centerMode: false,
		infinite: false,
		slidesToShow : 6,
		touchThreshold: 20,
		arrows: true,
		prevArrow: '<button class="slick-prev"><i class="icon-arrow_left"></i></button>',
		nextArrow: '<button class="slick-next"><i class="icon-arrow_right"></i></button>',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow : 5
				}
			},
			{
				breakpoint: 992,
				settings: {
					arrows: false,
					centerMode: false,
					infinite: true,
					variableWidth : true
				}
			},
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4
        }
      },
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});
}

/* Слайдер превью видео */
var $promo_slider_video = $('.promo_slider_video');
if($promo_slider_video.length) {
  $promo_slider_video.slick({
    centerMode: false,
    infinite: false,
    slidesToShow : 4,
    touchThreshold: 20,
    arrows: true,
    prevArrow: '<button class="slick-prev"><i class="icon-arrow_left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="icon-arrow_right"></i></button>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow : 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          centerMode: true,
          infinite: true,
          variableWidth : true,
          slidesToShow: 1,
        }
      },
    ]
  });
}

/**/
var $slider = $('.main_slider');
if($slider.length) {
	$slider.on('init', function(e, slick) {
		$('.slick-active').addClass('hover');
	});

	$slider.slick({
		centerMode: true,
		variableWidth : true,
		arrows: true,
		slide: '.main_slider__slide',
		dots : true,
		swipeToSlide : true,
		slidesToShow : 3,
		prevArrow: "<button class='slick-arrow__prev'><i class='icon-arrow_left'></i></button>",
		nextArrow: "<button class='slick-arrow__next'><i class='icon-arrow_right'></i></button>",
		responsive: [
			{
				breakpoint: 2500,
				settings: {
					slidesToShow: 1,
				}
			},
		]
	});

	$slider.on('afterChange', function(e, slick, currentSlide) {
		$('.slick-active').addClass('hover');
	});

	$slider.on('beforeChange', function(e, slick, currentSlide) {
		$('.slick-active').removeClass('hover');
	});
}



/* Фильтр игр в выпадающем меню */
var $categories = $('.categories-dropdown__list a');
var $active_category = $categories.filter('.active');
var $categories_prewiev_list = $('.sidebar__games_wrap .sidebar__game');
var $title = $('.popular_actions h3 span');
var flag = true;
var timeout;
$categories_prewiev_list.hide();

if($active_category.length) {
	filterCategory($active_category.find('span').text().toLowerCase());
} else {
	filterCategory(window.HEADER_MENU_POPULAR_GAMES_TITLE);
}

$categories.on('mouseenter', function (e) {
	timeout = setTimeout(function () {
		filterCategory($(this).find('span').text().toLowerCase());
	}.bind(this), 500);

});

$categories.on('mouseleave', function (e) {
	clearTimeout(timeout);
});

function filterCategory(active_category_title) {
	$categories_prewiev_list.hide().filter('[data-category="'+active_category_title+'"]').fadeIn();
	$title.text(active_category_title);
}



/* Анимация на главной */
var el = document.querySelector('.topbg');
var isMD = window.matchMedia("(max-width: 991px)").matches;
if(el && !isMD) {
  var planets = document.querySelector('.topbg__planets');
  var sun = document.querySelector('.sun');

  sun.style.opacity = 1;

  var targets = {
    start: '#30305D',
    end: '#3175BB',
    opacity : 1,
    translateY: 0
  }

  var animation = anime({
    targets: targets,
    start: '#764514',
    end: '#e54c2b',
    opacity : 0,
    translateY : 100,
    duration: 1000,
    easing: 'linear',
    loop: true,
    direction: 'alternate',
    autoplay: false,
    update: function(a) {
      var gradient_1 	= a.animations[0].currentValue;
      var gradient_2 	= a.animations[1].currentValue;
      var opacity 	= a.animations[2].currentValue;
      var transform 	= a.animations[3].currentValue;
      el.style.backgroundImage = 'linear-gradient(180deg, '+gradient_1+' 0%, '+gradient_2+' 100%)';
      planets.style.opacity 	= opacity;
      planets.style.filter  	= "grayscale("+(1-opacity)+")";
      sun.style.transform	  	= 'translateY('+transform+'px)';
    }
  });


  var sun_shadow_1 = anime({
    targets: '#shadow_1',
    d: [
      {
        value : 'M 101.2 46.2 C 101.2 47.2 101.6 49.6 103.8 49.8 C 104.5 49.9 101 51.6 100.9 52.3 C 100.6 55.1 101.2 59.2 102.7 61.2 C 102.7 61.2 100 61.1 95.1 70.2 C 92.4 75.3 88.5 79.7 84 83.4 C 82.8 84.4 82.8 90 82.8 90 C 77.7 85.7 76.5 90.2 76.9 92.6 C 76.9 92.6 70.6 91.2 68.5 91.9 C 66.3 92.6 63.1 94.9 62 96.4 C 62 96.4 56.2 94.3 53.3 94.3 C 45.7 94.3 38.6 92.6 32.2 89.5 C 31.2 89 28.8 90.6 27.3 90.8 C 27.3 90.8 27.9 85.3 25.3 87.8 C 25.3 87.8 23.9 84.7 22.5 83.4 C 18.7 79.8 15 80.2 15 80.2 C 15.1 78 14.4 74.7 13.3 73 C 12.5 71.8 7.7 70.4 5.5 71.2 C 5.5 71.2 8.9 64.9 8.1 62.6 C 7.8 61.7 7 60.5 5.2 60.8 C 5.2 60.8 5.5 51.7 5.5 47.3 C 5.5 21.3 26.9 0.3 53.3 0.3 C 61.9 0.3 70 2.5 77 6.5 C 81.4 9 85.3 11.9 88.6 15.7 C 93.3 21.2 95.9 19.7 95.9 19.7 C 95.9 19.7 93.3 21.7 95 24.4 C 96.7 27.2 98.1 30.7 99.1 34.1 C 100.5 38.3 101.2 41.6 101.2 46.2 Z'
      },
      {
        value : 'M 101.3 47.3 C 101.3 48.3 102 49.7 104.2 49.9 C 104.9 50 101.1 51.7 101 52.3 C 100.7 55.1 105.6 61.4 107.1 63.4 C 107.1 63.4 100.1 61.1 95.2 70.2 C 92.5 75.3 88.5 79.7 84.1 83.4 C 82.9 84.4 83.2 90.2 83.2 90.2 C 80.1 87.4 75.3 88.5 75.7 90.9 C 75.7 90.9 70.6 91.3 68.6 92 C 66.4 92.7 63.7 99.5 62.6 101 C 62.6 101 56.3 94.4 53.5 94.4 C 45.9 94.4 38.7 92.7 32.4 89.6 C 31.4 89.1 28.3 90.2 27.2 91.7 C 27.2 91.7 27.3 87.4 24.6 89.8 C 24.6 89.8 24 84.9 22.7 83.6 C 18.9 80 15.9 79.5 15.9 79.5 C 16 77.3 14.6 74.9 13.5 73.2 C 12.7 72 8.4 70.8 6.2 71.7 C 6.2 71.7 9.1 65.1 8.3 62.8 C 8 61.9 3.5 62.3 0 65.5 C 0 65.5 5.7 51.8 5.7 47.5 C 5.7 21.5 27.2 0.5 53.6 0.5 C 62.3 0.5 70.4 2.7 77.4 6.7 C 81.8 9.2 85.7 12.1 89.1 15.9 C 93.8 21.4 97 19.8 97 19.8 C 97 19.8 93.8 22 95.5 24.7 C 97.2 27.5 98.6 31 99.6 34.4 C 100.7 38.3 101.3 42.7 101.3 47.3 Z'
      },
      {
        value : 'M 101.2 47.4 C 101.2 48.4 104.1 49.6 106.3 49.9 C 107 50 101 51.8 100.9 52.4 C 100.6 55.2 103.4 60.1 104.8 62.1 C 104.8 62.1 99.9 61.1 95.1 70.2 C 92.4 75.3 88.5 79.7 84 83.3 C 82.8 84.3 84.9 92.7 84.9 92.7 C 81.9 89.9 75.8 89.1 76.1 91.5 C 76.1 91.5 70.5 91.2 68.4 91.8 C 66.2 92.5 63.1 96.3 62 97.8 C 62 97.8 56.1 94.2 53.3 94.2 C 45.7 94.2 38.6 92.5 32.2 89.4 C 31.2 88.9 26.4 93.2 25.2 94.6 C 25.2 94.6 27 86.9 24.4 89.4 C 24.4 89.4 23.8 84.6 22.5 83.4 C 18.7 79.8 15.7 79.3 15.7 79.3 C 15.8 77.1 14.4 74.7 13.3 73.1 C 12.5 71.9 3 73.1 0.8 74 C 0.8 74 8.9 65 8.1 62.7 C 7.8 61.8 5 62.1 3.2 62.4 C 3.2 62.4 5.5 51.8 5.5 47.4 C 5.5 21.5 26.9 0.5 53.3 0.5 C 61.9 0.5 70 2.7 77 6.7 C 81.4 9.1 85.3 12.1 88.6 15.9 C 93.3 21.3 98.9 18.5 98.9 18.5 C 98.9 18.5 93.4 21.9 95 24.6 C 96.7 27.4 98.1 30.8 99.1 34.2 C 100.6 38.4 101.2 42.8 101.2 47.4 Z'
      }
    ],
    easing: 'linear',
    duration: 600,
    delay: 2000,
    loop: true,
    autoplay : true,
    direction: 'alternate',
  });

  var sun_transform = anime({
    targets: '.sun-transform',
    translateY : -20,
    easing: 'linear',
    duration: 600,
    delay: 2000,
    loop: true,
    autoplay : true,
    direction: 'alternate',
  });

  var sun_shadow_2 = anime({
    targets: ".sun__shadow_2",
    rotate : -360,
    easing: 'linear',
    duration: 14000,
    loop: true,
    autoplay : true,
  });

  var sun_shadow_3 = anime({
    targets: ".sun__shadow_3",
    rotate : 360,
    easing: 'linear',
    duration: 14000,
    loop: true,
    autoplay : true,
  });



  $(window).on('scroll', function(e) {
    var offset = window.pageYOffset;

    if(offset > 100) {
      sun_transform.pause();
      sun_shadow_1.pause();
    } else {
      sun_transform.play();
      sun_shadow_1.play();
    }

    if(offset > 400) {
      sun_shadow_2.pause();
      sun_shadow_3.pause();
      return
    };
    sun_shadow_2.play();
    sun_shadow_3.play();
    animation.seek(animation.duration * (window.pageYOffset / 300));
  });
}


/* Селекты */
function selectTemplate(optionEl) {
	var image = optionEl.data('image');
	var icon = optionEl.data('icon');

	if(image) {
		return '<img src="' + image + '">' + optionEl.text();
	}

	if(icon) {
		return '<i class="' + icon + '"></i>' + optionEl.text();
	}

	return optionEl.text();
}

// var $country_select = $('#country_select');
//
// function setCountryCode() {
// 	var $selected_opt = $('option:selected', $country_select);
// 	var code = $selected_opt.data('code');
// 	var mask = $selected_opt.data('mask');
// 	var placeholder = mask.replace(/#/g,"0");
//  	$('#country_code').val(code);
// 	$('#phone').attr('placeholder', placeholder).val('').inputmask(mask);
// }


var $selects = $('select');
if($selects.length) {
	$('select').fancySelect({
    forceiOS : true,
		includeBlank: true,
		optionTemplate: function(optionEl) {
			return selectTemplate(optionEl);
		},

		triggerTemplate: function(optionEl) {
			return selectTemplate(optionEl);
		},
	});

	// setCountryCode();
  //
	// $('#country_select').on('change.fs', function (e) {
	// 	setCountryCode();
	// });
}



/* Модальное окно автризаций */

var $modal_forms 	= $('.form-slider__item');
var $modal_titles 	= $('.modal-auth__header h3');
var $modal_footers 	= $('.modal-auth__footer_row');
var $modal_buttons 	= $('.modal-auth__sidebar_button');
var $modal_note 	= $('.modal-auth__sidebar_note');
var $form_messages  = $('.form_message');

$('#authModal')
	.on('show.bs.modal', function (e) {
		var form = $(e.relatedTarget).data('form-selector');
		$('body').addClass('authModal-open');
	  	changeForm(form ? form : '.form_auth');
	})
	.on('hide.bs.modal', function (e) {
		$('body').removeClass('authModal-open');
	});

$('.change_form').on('click', function (e) {
	e.preventDefault();
	var form = $(this).data('form-selector');
	changeForm(form);
});


function changeForm(form) {
  	$modal_forms.hide();
  	$modal_titles.hide();
  	$modal_footers.hide();
  	$modal_buttons.hide();
  	$modal_note.hide();
  	$form_messages.hide();
  	$(form).css('opacity', 1).fadeIn();

}

$('.hamburger').on('click', function (e) {
	$('.modal').modal('hide');
});

// $('#form_registration').on('submit', function (e) {
// 	e.preventDefault();
// 	$modal_forms.css('opacity', 0);
// 	$('.form_message__success').fadeIn().css('display', 'flex');
// })


// $('#changeLoginModal').modal('show');

$('.form-label-animation .form-control').each(function () {
	if (
		this.value.length > 0 ||
		$(this).is(':focus') ||
    this.autofocus
	) {
    $(this).siblings('label').addClass('active');
	} else {
    $(this).siblings('label').removeClass('active');
	}
});

$(document).on('change', '.form-label-animation .form-control', function () {
	if (this.value.length !== 0) {
    $(this).siblings('label').addClass('active');
	}
});

$(document).on('focus', '.form-label-animation .form-control', function () {
	$(this).siblings('label').addClass('active');
});

$(document).on('blur', '.form-label-animation .form-control', function () {
  if (this.value.length === 0) {
    $(this).siblings('label').removeClass('active');
  }
});

$(document).on('pjax:success', function () {
  $('.form-label-animation .form-control').each(function () {
    if (
      this.value.length > 0 ||
      $(this).is(':focus') ||
      this.autofocus
    ) {
      $(this).siblings('label').addClass('active');
    } else {
      $(this).siblings('label').removeClass('active');
    }
  });
});

/* Выбор тарифа в моб версии */
var $plans = $('.tariff-plan');
$('.toggle-plans input').on('change', function (e) {
	var target = $(this).val();
	$plans.hide().filter('[data-show="'+target+'"]').fadeIn();
});

/* Изменение логотипа при открытии модалки триала */
$('#trialModal')
	.on('show.bs.modal', function (e) {
		$('body').addClass('trialModalOpen');
	})
	.on('hide.bs.modal', function (e) {
		$('body').removeClass('trialModalOpen');
	});

function cardNumberValidator(cardForm) {
	var card_number_length = cardForm.getCardNumber().length;
	var current_card_mask_length = cardForm.creditCardNumberMask.length;

	if(card_number_length < current_card_mask_length) {
    cardForm.cardNumberInput.parent().addClass('has-error');
		return false;
	}
  cardForm.cardNumberInput.parent().removeClass('has-error');
	return true;
}

function cardNameValidator(cardForm) {

	var card_name_length = cardForm.getName().length;
	if(!card_name_length) {
    cardForm.nameInput.parent().addClass('has-error');
		return false;
	}
  cardForm.nameInput.parent().removeClass('has-error');
	return true;
}

function expiryValidate(cardForm) {
	var expiryMonth = cardForm.getExpiryMonth();
	var expiryYear = cardForm.getExpiryYear();

	if(!CardJs.isExpiryValid(expiryMonth, expiryYear)) {
    cardForm.expiryMonthYearInput.parent().addClass('has-error');
		return false;
	}
  cardForm.expiryMonthYearInput.parent().removeClass('has-error');
	return true;
}

function cvvValidate(cardForm) {
	var cvc = cardForm.getCvc();
	if(cvc.length < 3) {
    cardForm.cvcInput.parent().addClass('has-error');
		return false;
	}
  cardForm.cvcInput.parent().removeClass('has-error');
	return true;
}

/* Открытие модалки по ссылке */
var location_hash = window.location.hash;
var $has_modal = $(location_hash);

if($has_modal.length && $has_modal.hasClass('modal')) {
	$has_modal.modal('show');
}

/* configure progress bar */
NProgress.configure({showSpinner: false});

toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "200",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

/* start progress bar */
// NProgress.start();
/* end progress bar */
// NProgress.done();

/* show notofocations */
// toastr.success('Saved successfully');
// toastr.error('Saved successfully');